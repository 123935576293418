import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { NavigateFunction } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useFieldArray, useForm } from "react-hook-form";
import { Clear, AddCircle, CloseFullscreenRounded } from "@mui/icons-material";
import { Box, Button, DialogActions, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Tooltip } from "@mui/material";
import { DocumentSnapshot } from "./DocumentSnapshot";
import { AccountingNoteEntryDTO } from "../../../../domain/AccountingNote/AccountingNoteEntryDTO";
import { AccountingNoteDTO } from "../../../../domain/AccountingNote/AccountingNoteDTO";
import { DocumentDTO } from "../../../../domain/Document/DocumentDTO";
import { HttpService } from "../../../../services/HttpService";
import validationSchema from "./index.validationschema";
import { DocumentWorkflowStateIds } from "../../../../domain/Document/DocumentWorkflowStatesIds";
import { documentCanBeOpened } from "../../../../helpers/DocumentHelpers";
import myTheme from "../../../../helpers/themeHelper";
import { PdfViewer } from "../PdfViewer";
type InvoiceProps = {
  document: DocumentDTO,
  navigate: NavigateFunction,
  revalidateDocument: () => void,
  onClose: () => void,
}

const Invoice: React.FC<InvoiceProps> = (props) => {
  const { document, navigate, onClose, revalidateDocument } = props;
  const {
    handleSubmit,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<AccountingNoteDTO>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange"
  });
  const { replace, append, remove, update } = useFieldArray({ control, name: "accountingNoteEntries" });

  useEffect(() => {
    if (!document || !documentCanBeOpened(document)) {
      return;
    }

    HttpService.getInstance(navigate).client.get<AccountingNoteDTO>(`/documents/note/${document.id}`)
      .then(({ data }) => {
        reset({
          ...data,
          customerId: data.customerId,
          currencyCode: data.currencyCode,
          customerAddress: data.customerAddress,
          customerAddressRecipient: data.customerAddressRecipient,
          customerName: data.customerName,
          customerTaxId: data.customerTaxId,
          documentId: data.documentId,
          invoiceId: data.invoiceId,
          invoiceTotal: data.invoiceTotal,
          subTotal: data.subTotal,
          totalVat: data.totalVat,
          vendorAddress: data.vendorAddress,
          vendorAddressRecipient: data.vendorAddressRecipient,
          vendorName: data.vendorName,
          vendorTaxId: data.vendorTaxId,

          accountingNoteEntries: _.orderBy(data.accountingNoteEntries, ["description", "value"]),
        });

        trigger();
      });
  }, [navigate, props.document.id]);

  const documentCanBeEdited = useMemo(() => {
    switch (document.workflowStateId) {
      case DocumentWorkflowStateIds.Modified:
      case DocumentWorkflowStateIds.HighConfidence:
      case DocumentWorkflowStateIds.LowConfidence:
      case DocumentWorkflowStateIds.Approved:
      case DocumentWorkflowStateIds.Archived:
        return true;
      default: return false;
    }
  }, [document.workflowStateId]);
  

  const accountingNote = getValues();
  if (!accountingNote || Object.values(accountingNote).length === 0) {
    return <span>No invoice</span>;
  }

  const onNoteEntriesCollapse = () => {
    if (!accountingNote) {
      return;
    }

    let totalValue = accountingNote.accountingNoteEntries?.reduce((acc, entry) => acc + entry.value, 0) || 0;

    totalValue = Number(totalValue.toFixed(2));

    const accountingNoteEntries = [{
      ...new AccountingNoteEntryDTO(),
      accountingNoteId: accountingNote.documentId,
      value: totalValue,
      quantity: 1,
      description: "",
      debitAccount: "N/A",
      creditAccount: "N/A",
      currencyCode: accountingNote.currencyCode,
    }];

    replace(accountingNoteEntries);
  };

  const onNoteEntryAdded = () => {
    if (!accountingNote) {
      return;
    }

    const accountingNoteEntries = [...accountingNote.accountingNoteEntries];
    accountingNoteEntries.push({
      ...new AccountingNoteEntryDTO(),
      accountingNoteId: accountingNote.documentId,
    });

    append({ ...new AccountingNoteEntryDTO(), accountingNoteId: accountingNote.documentId });
  };

  const onNoteEntryDeleted = (index: number): void => remove(index);

  const onNoteEntryChanged = (
    index: number,
    key: keyof AccountingNoteEntryDTO,
    value: any
  ) => {
    if (!accountingNote) {
      return;
    }

    const entry = { ...new AccountingNoteEntryDTO(), ...accountingNote.accountingNoteEntries[index] };
    switch (key) {
      case "creditAccount":
      case "debitAccount":
      case "description":
        entry[key] = value;
        break;
      case "value":
      case "quantity":
        entry[key] = value;
        break;
      default:
        return;
    }

    update(index, entry);
  };

  const onNoteChanged = (key: keyof AccountingNoteDTO, value: any) => {
    if (!accountingNote) {
      return;
    }

    setValue(key, value, { shouldValidate: true, shouldTouch: true });
  };

  const onSubmit = (data: AccountingNoteDTO) => {
    const toastId = toast.loading("Operation is pending.", {
      position: "top-center",
      theme: "colored",
    });
    data.accountingNoteEntries.forEach(element => {
      element.creditAccount = "N/A";
      element.debitAccount = "N/A";
    });
    HttpService.getInstance(navigate)
      .client.put<AccountingNoteDTO>(`/accountingnotes`, data)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Request Failed");
        }
        toast.update(toastId, {
          render: "The accounting journal was successfully saved.",
          type: "success",
          isLoading: false,
          autoClose: null,
        });

        revalidateDocument();
      })
      .catch(() => toast.dismiss(toastId))
      .finally(props.onClose);
  };

  return (
    <ThemeProvider theme={myTheme}>
      <div style={{ display: "flex"}}>
      <Box>
        <Box>
          <DocumentSnapshot 
            accountingNote={accountingNote} 
            isEditable={documentCanBeEdited} 
            errors={errors} 
            onNoteChanged={onNoteChanged} 
            register={register}
          />
        </Box>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}  >
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table sx={{ minWidth: 800 }} aria-label="customized table">
              <TableHeadElement actionsEnabled={documentCanBeEdited} />
              <TableBody>
                {(!accountingNote.accountingNoteEntries || accountingNote.accountingNoteEntries.length === 0) &&
                  <StyledTableRow><Box sx={{ display: 'flex', minHeight: '20px' }}></Box></StyledTableRow>}
                {accountingNote.accountingNoteEntries?.map(
                  (noteEntry, noteIndex) => (
                    <StyledTableRow key={noteIndex}>
                      <StyledTableCell component="th" scope="row">
                        <TextField size="small" label="Description" variant="standard" fullWidth
                          value={noteEntry.description}
                          error={errors.accountingNoteEntries && errors.accountingNoteEntries[noteIndex]?.description?.message !== undefined}
                          helperText={errors.accountingNoteEntries ? errors.accountingNoteEntries[noteIndex]?.description?.message : null}
                          {...register(`accountingNoteEntries.${noteIndex}.description`, { disabled: !documentCanBeEdited })}
                          onChange={(event) => {
                            event.stopPropagation();
                            onNoteEntryChanged(noteIndex, 'description', event.target.value);
                          }} />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <TextField size="small" label="Value" variant="standard"
                          value={noteEntry.value}
                          error={errors.accountingNoteEntries && errors.accountingNoteEntries[noteIndex]?.value?.message !== undefined}
                          helperText={errors.accountingNoteEntries ? errors.accountingNoteEntries[noteIndex]?.value?.message : null}
                          {...register(`accountingNoteEntries.${noteIndex}.value`, { disabled: !documentCanBeEdited })}
                          onChange={(event) => {
                            event.stopPropagation();
                            onNoteEntryChanged(noteIndex, 'value', event.target.value);
                          }} />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <TextField size="small" label="Quantity" variant="standard"
                          value={noteEntry.quantity}
                          error={errors.accountingNoteEntries && errors.accountingNoteEntries[noteIndex]?.quantity?.message !== undefined}
                          helperText={errors.accountingNoteEntries ? errors.accountingNoteEntries[noteIndex]?.quantity?.message : null}
                          {...register(`accountingNoteEntries.${noteIndex}.quantity`, { disabled: !documentCanBeEdited })}
                          onChange={(event) => {
                            event.stopPropagation();
                            onNoteEntryChanged(noteIndex, 'quantity', event.target.value);
                          }} />
                      </StyledTableCell>
                      
                      
                      { documentCanBeEdited &&
                      <StyledTableCell align="right">
                        <Clear sx={{ color: '#d43d3d', cursor: 'pointer' }} onClick={() => onNoteEntryDeleted(noteIndex)} />
                      </StyledTableCell>
                      }
                    </StyledTableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        { documentCanBeEdited &&
        <Box sx={{ display: "flex", height: 0, flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Tooltip title="Add a new entry">
              <AddCircle onClick={onNoteEntryAdded} color="primary" sx={{  cursor: "pointer", backgroundColor: "white" }} />
            </Tooltip>
            <Tooltip title="Collapse all entries into a single one">
              <CloseFullscreenRounded onClick={onNoteEntriesCollapse} color="primary" sx={{  cursor: "pointer", backgroundColor: "white" }} />
            </Tooltip>
          </Box>
        </Box>
        }
        { documentCanBeEdited &&
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button variant="outlined" onClick={handleSubmit(onSubmit)}>Save</Button>
        </DialogActions>
        }
      </Box>
      
      
      </div>
    </ThemeProvider>
  );
};

const TableHeadElement: React.FC<{ actionsEnabled: boolean}> = (props) => (
  <TableHead>
    <TableRow>
      <StyledTableCell>Description</StyledTableCell>
      <StyledTableCell sx={{ width: '200px' }} align="center">Value</StyledTableCell>
      <StyledTableCell sx={{ width: '160px' }} align="center">Quantity</StyledTableCell>
      {props.actionsEnabled && <StyledTableCell sx={{ width: '60px' }} align="right">Action</StyledTableCell>}
    </TableRow>
  </TableHead>
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export { Invoice };
